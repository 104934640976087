const blue = {
  "100": "#E9F3FF",
  "300": "#c4dcff",
  "400": "#5797dd",
  "450": "#4B90E6",
  "500": "#2972cc",
  "700": "#155eb8",
  "900": "#003f8c",
  A400: "#bcd0ee",
};

export default blue;
