const grey = {
  "100": "#f6f7fb",
  "200": "#ebeff8",
  "300": "#dfe4f0",
  "400": "#99a8c4",
  "500": "#7E8EAA",
  "600": "#F0F3F8",
  "700": "#5c6980",
  "900": "#1b2c3c",
  A400: "#e0e0e0",
};

export default grey;
