import React, { FC, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { ReactComponent as ReporterIcon } from "./icons/reporter.svg";
import { ReactComponent as ChefIcon } from "./icons/chef.svg";
import { ReactComponent as ManagerIcon } from "./icons/manager.svg";
import { ReactComponent as DoctorIcon } from "./icons/doctor.svg";
import { ReactComponent as PostWomanIcon } from "./icons/postwoman.svg";
import { ReactComponent as LaborerIcon } from "./icons/laborer.svg";
import { ReactComponent as MarioIcon } from "./icons/mario.svg";
import { ReactComponent as ProfessorIcon } from "./icons/professor.svg";
import { ReactComponent as FreelancerIcon } from "./icons/freelancer.svg";
import { IconContainer } from "./theme/IconContainer";
import { LoginInfoCards } from "./components/LoginInfoCards";

interface IhkData {
  name: string;
  url: string;
}

export const App: FC = () => {
  const { t } = useTranslation();
  const [ihks, setIhks] = useState<IhkData[]>([]);
  const [selectedIhk, setSelectedIhk] = useState<string>();

  useEffect(() => {
    fetch("/data/ihks.json")
      .then((resp) => resp.json())
      .then((ihkData: IhkData[]) => {
        setIhks(ihkData);
      });
  }, []);

  const handleIhkChange = (e: SelectChangeEvent<string>) => {
    const ihkUrl = e.target.value;
    setSelectedIhk(ihkUrl);
    window.location.href = ihkUrl;
  };

  const makeLogoLink = (ihk: IhkData): string => {
    return ihk.url.replace("#/tibrosPN/login", "custom/logo.png");
  };

  const loginInfoCards = [
    {
      header: t("loginInfoCards.cardOne.header"),
      content: t("loginInfoCards.cardOne.content"),
    },
    {
      header: t("loginInfoCards.cardTwo.header"),
      content: t("loginInfoCards.cardTwo.content"),
    },
    {
      header: t("loginInfoCards.cardThree.header"),
      content: t("loginInfoCards.cardThree.content"),
    },
  ];

  return (
    <Box
      sx={{
        background: "linear-gradient(to left bottom, #306fbc, #80b2ef)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Link href="https://www.ihk.de/">
          <img
            style={{ position: "absolute", top: 16, left: 16, maxHeight: 51 }}
            src="/logo.png"
            alt="ihk-logo"
          />
        </Link>
        <Grid container sx={{ mt: { sm: 10, md: 0 } }}>
          <Grid
            item
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { sm: "center", md: "flex-end" },
              pr: { sm: 2, md: 10 },
              pl: { sm: 2, md: 0 },
            }}
          >
            <Box
              sx={{
                maxWidth: 483,
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 800,
                  color: (theme: Theme) => theme.palette.common.white,
                  mb: 1,
                }}
                component="h1"
              >
                {t("examinerPortal")}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 800,
                  fontSize: 62,
                  pb: 3,
                  color: (theme: Theme) => theme.palette.common.white,
                  lineHeight: "74.4px",
                  mb: 1,
                }}
              >
                {t("chooseYourIhk")}
              </Typography>
              <FormControl fullWidth>
                <Select
                  sx={{
                    borderRadius: 1,
                    backgroundColor: (theme: Theme) =>
                      theme.palette.common.white,
                    mb: 5,
                  }}
                  displayEmpty
                  value={selectedIhk ?? ""}
                  onChange={handleIhkChange}
                >
                  <MenuItem value="">{t("yourIhkName")}</MenuItem>
                  {ihks.map((ihk) => (
                    <MenuItem key={ihk.url} value={ihk.url}>
                      {ihk.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  whiteSpace: "pre-wrap",
                  lineHeight: "24px",
                  color: (theme: Theme) => theme.palette.common.white,
                }}
                component="h2"
              >
                {t("introductionText")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            sx={{
              display: "flex",
              pl: { sm: 0, md: 10 },
              justifyContent: { sm: "center", md: "flex-start" },
            }}
          >
            <Box
              sx={{
                maxWidth: 560,
                mt: { sm: 5, md: 0 },
                mb: { sm: 10, md: 0 },
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  sm={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <IconContainer Icon={ReporterIcon} />
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <IconContainer Icon={ChefIcon} />
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <IconContainer Icon={ManagerIcon} />
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <IconContainer Icon={DoctorIcon} />
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <IconContainer Icon={PostWomanIcon} />
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <IconContainer Icon={LaborerIcon} />
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <IconContainer Icon={MarioIcon} />
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <IconContainer Icon={ProfessorIcon} />
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <IconContainer Icon={FreelancerIcon} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            bottom: 8,
            right: 16,
            left: 16,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link
            href="https://www.tibros.de/impressum"
            target="_blank"
            sx={{
              mr: 2,
              textDecorationColor: (theme: Theme) => theme.palette.common.white,
            }}
          >
            <Typography
              sx={{
                color: (theme: Theme) => theme.palette.common.white,
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              {t("about")}
            </Typography>
          </Link>
          <Link
            href="https://www.tibros.de/datenschutz"
            target="_blank"
            sx={{
              textDecorationColor: (theme: Theme) => theme.palette.common.white,
            }}
          >
            <Typography
              sx={{
                color: (theme: Theme) => theme.palette.common.white,
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              {t("privacy")}
            </Typography>
          </Link>
        </Box>
      </Box>
      <LoginInfoCards loginInfoCards={loginInfoCards} />
      <Box
        sx={{
          mb: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "80%",
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            justifyContent: { xs: "center", md: "space-evenly" },
          }}
        >
          {ihks.map((ihk, index) => (
            <Box key={`link-${index}`} sx={{ minWidth: "200px" }}>
              <a href={ihk.url}>
                <img
                  src={makeLogoLink(ihk)}
                  style={{ height: 40 }}
                  alt={ihk.name + " Logo"}
                />
              </a>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

