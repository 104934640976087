import { createTheme } from "@mui/material/styles";
import { blue, common, green, grey, red, yellow } from "./colors";

export const nocTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: 13,
          paddingRight: 32,
          paddingBottom: 13,
          paddingLeft: 32,
          lineHeight: "22px",
        },
      },
    },
    MuiCardActions: {
      defaultProps: {
        sx: {
          p: 3,
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "medium",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 320, // 600,
      md: 800, // 950,
      lg: 1200, // 1200,
      xl: 1456, // 1536,
    },
  },
  palette: {
    mode: "light",
    primary: {
      light: "#3973CF",
      main: blue["500"],
      dark: "#295FBB",
      contrastText: "#fff",
    },
    secondary: {
      light: green["300"],
      main: green["500"],
      dark: green["700"],
      contrastText: common.white,
    },
    error: {
      main: red["800"],
    },
    success: {
      main: "#297C00",
    },
    info: {
      main: blue["A400"],
    },
    warning: {
      main: yellow["800"],
    },
    text: {
      primary: grey["900"],
      secondary: grey["500"],
    },
    background: {
      default: common.white,
      paper: common.white,
    },
    common: {
      white: common.white,
      black: common.black,
    },
  },
  typography: {
    fontFamily: "Mulish, Roboto, sans-serif",
    allVariants: {
      // color: grey['900'],
    },
    button: {
      color: common.white,
      disableElevation: "true",
      fontSize: 18,
      fontWeight: 700,
      textTransform: "none",
      whiteSpace: "nowrap",
    },
    body1: {
      // fontSize: 16,
      // fontWeight: 400
    },
    body2: {
      // fontSize: 14,
      // fontWeight: 400,
    },
    caption: {
      // fontSize: 12,
      fontWeight: 500,
      color: grey["500"],
    },
    h2: {
      fontSize: 48,
      fontWeight: 800,
    },
    h3: {
      fontSize: 32,
      fontWeight: 800,
    },
    h4: {
      fontSize: 26,
      fontWeight: 800,
    },
    h5: {
      fontSize: 22,
      fontWeight: 700,
    },
    h6: {
      fontSize: 18,
    },
  },
  shape: {
    borderRadius: 8,
  },
});
