import React, { FC } from "react";
import { SvgIcon } from "@mui/material";

interface IconContainerProps {
  Icon: React.ElementType;
}

export const IconContainer: FC<IconContainerProps> = ({ Icon }) => {
  return (
    <SvgIcon
      component={Icon}
      viewBox="0 0 160 160"
      sx={{ width: { sm: 100, md: 160 }, height: { sm: 100, md: 160 } }}
    />
  );
};
