const green = {
  "100": "#eaffeb",
  "300": "#d6fad6",
  "400": "#79c16c",
  "500": "#3c8e2e",
  "700": "#127c00",
  "900": "#1d6511",
  A400: "#acff9d",
};

export default green;
