import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import React, { FC } from "react";

interface LoginInfoCard {
  header: string;
  content: string;
}

interface LoginInfoCardsProps {
  loginInfoCards: LoginInfoCard[];
}

export const LoginInfoCards: FC<LoginInfoCardsProps> = (props) => {
  const { loginInfoCards } = props;
  return (
    <Grid container justifyContent="center">
      <Box
        sx={{
          p: { xs: 3, lg: 5 },
          display: "flex",
          gap: { xs: 3, lg: 5 },
          justifyContent: "center",
          flexWrap: { xs: "wrap", lg: "nowrap" },
        }}
      >
        {loginInfoCards.map((card, index) => (
          <Card sx={{ maxWidth: { lg: 300 } }} key={`${card.header}-${index}`}>
            <CardHeader
              sx={{
                color: (theme: Theme) => theme.palette.primary.main,
              }}
              title={card.header}
            />
            <CardContent>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  whiteSpace: "pre-wrap",
                  lineHeight: "24px",
                  color: (theme: Theme) => theme.palette.primary.main,
                }}
              >
                {card.content}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Grid>
  );
};
